import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import features1 from '../../assets/images/features1.png'
import features2 from '../../assets/images/features2.png'
import features3 from '../../assets/images/features3.png'
import features4 from '../../assets/images/features4.png'
import features5 from '../../assets/images/features5.png'
import features6 from '../../assets/images/features6.png'
import features7 from '../../assets/images/features7.png'

const OurHistoryContentOne = () => {
    return (
        <section className="history-area ptb-50 bg-fafafb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Our Features
                    </span>
                    <h2>The Tools You Need to Succeed</h2>
                    <p>Percorso provides features designed to support administrators, teachers and students with field education and distance learning. Our feedback focused system enables students to establish goals and track their progress throughout the year with the aide of their teacher's feedback and guidance.</p>
                </div>
                

                <ol className="timeline history-timeline">
                    <li className="timeline-block">

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Manage Program Objectives</h3>
                                        <p>Manage, measure, and support the long-term development objectives for your program. Customize Percorso to guide the formation of your users and keep track of their progress along the way.</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={features1} alt="about" className="imgborder" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">

                        <div className="timeline-content">
                            <div className="row align-items-center">

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={features2} alt="about" className="imgborder" />
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Goal Management</h3>
                                        <p>Create user-specific goals that guide development and formation throughout time in the development. Unite user-specific goals with long-term program objectives to observe progress over time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Ongoing Accountability & Feedback</h3>
                                        <p>Use Percorso to encourage accountability, reflection, and communication throughout a user's development. Collect and consolidate feedback from a variety of sources to offer broad-based coaching and commentary on progress.</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={features3} alt="about" className="imgborder" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">

                        <div className="timeline-content">
                            <div className="row align-items-center">

                            <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={features4} alt="about" className="imgborder" />
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Analytics & Reporting</h3>
                                        <p>Evaluate program-wide growth and performance in the reporting dashboard. Leverage your data to spot trends and indicators of your institution's overall well-being. Export your reports to share with others or for safe keeping.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li className="timeline-block">

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Comprehensive Evaluation Support</h3>
                                        <p>Easily take advantage of your collective program data to develop meaningful and accurate evaluations built upon user goals and institutional objectives.</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={features5} alt="about" className="imgborder" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="timeline-block">

                        <div className="timeline-content">
                            <div className="row align-items-center">

                            <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={features6} alt="about" className="imgborder" />
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>User Growth Tracking</h3>
                                        <p>Provide a comprehensive picture of user achievements and proficiency to assess preparedness and elevate a user's skills to the next level.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li className="timeline-block">

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Online Document Management</h3>
                                        <p>Ditch the paperwork and start managing your assignments, evaluations, videos, and more online. Maintain your documents in one place and access them from anywhere.</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={features7} alt="about" className="imgborder" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
                <div>&nbsp;</div>
            </div>
        </section>
    )
}

export default OurHistoryContentOne